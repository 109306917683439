/* Navigation Styles*/

.align__center {
  align-items: center;
}

.slideFam {
    position: relative;

    height: 900px;
    canvas {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
    }
}

.slideFam {
  .slide__inner {
    h1 {
      font-size: 55px;
      font-weight: 400;
      line-height: 70px;

      margin-bottom: 50px;

      color: #ffffff;
    }
  }
  .slider__fornt {
    position: relative;
    z-index: 9;
    right: -136px;
    img {
      width: 100%;
    }
  }
}

.slide__inner {
  position: relative;
  z-index: 2;
}

/* Slider Style Two*/

.slider--2 {
  .slideFam {
    .slide__inner {
      position: relative;
      z-index: 2;
      left: -121px;

      padding-top: 97px;
      h1 {
        font-size: 40px;
        font-weight: 600;
        line-height: 60px;

        margin-bottom: 13px;
        padding-right: 10%;

        text-transform: uppercase;
      }
      p {
        font-size: 18px;
        line-height: 28px;

        margin-bottom: 30px;
        padding-right: 10%;

        color: #ffffff;
      }
    }
  }
}

.slider--2 .dg__btn.btn--white {
  color: #181818;
}

.slider--2 .dg__btn.btn--white:hover {
  color: #fff;
  background: #f7a901 none repeat scroll 0 0;
}

.dg__slider__area {
  position: relative;
  &.slider--3 {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
}

.dg__slider__area.slider--3::before {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  content: '';

  opacity: 0.5;
  background: #000 none repeat scroll 0 0;
}

.contentbox {
  position: relative;

  margin-top: 107px;
  padding-top: 28px;
  padding-bottom: 39px;
  padding-left: 39px;
}

.contentbox::before {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  content: '';

  opacity: 0.7;
  background: #361003 none repeat scroll 0 0;
}

.slideFam .contentbox .slide__inner h1 {
  font-size: 44px;
  line-height: 62px;

  margin-bottom: 25px;
}

.slider--one .slideFam .container {
  padding-top: 110px;
}

/* Responsive Css*/

@media #{$xlg-layout} {
  .slider--one .slideFam {
    height: 800px;
  }
  .slider--2 .slideFam {
    height: 800px;
    .slide__inner {
      padding-top: 97px;
    }
  }

  .slider--3 .slideFam {
    height: 800px;
  }

  .slider--one .slideFam .container {
    padding-top: 110px;
  }

  .slideFam .slider__fornt {
    right: 0;
  }
  .slider--2 .slideFam .slide__inner {
    left: 44px;
  }
}

@media #{$lg-layout} {
  .slider--one .slideFam {
    height: 700px;
  }
  .slider--2 .slideFam {
    height: 700px;
    .slide__inner {
      padding-top: 95px;
    }
  }
  .slider--3 .slideFam {
    height: 700px;
  }
  .slider--2 .slideFam .slide__inner {
    left: 0;
  }
  .slideFam .slide__inner h1 {
    font-size: 41px;
    line-height: 61px;
  }
  .slide__inner {
    padding-top: 23px;
  }
  .contentbox .slide__inner {
    padding-top: 0;
  }
  .slider--2 .slide__inner {
    padding-top: 82px;
  }
  .contentbox {
    margin-top: 110px;
  }
  .slideFam .slider__fornt {
    position: relative;
    right: 0;
  }
  .slider--one .slide__inner {
    padding-top: 0;
  }
  .slider--one .slideFam .container {
    padding-top: 110px;
  }
}

@media #{$md-layout} {
  .slider--one .slideFam {
    height: 500px;
  }
  .slider--2 .slideFam {
    height: 500px;
    .slide__inner {
      padding-top: 0;
      padding-bottom: 15px;
    }
  }
  .slider--3 .slideFam {
    height: 500px;
  }
  .slider--one .slideFam .container {
    padding-top: 0;
  }
  .slider--2 .slideFam .slide__inner {
    left: 0;
  }
  .bg-image--3 {
    background-position: 73% center;
  }
  .slideFam .slide__inner h1 {
    font-size: 39px;
    line-height: 55px;
  }
  .slide__inner {
    padding-top: 82px;
  }
  .contentbox .slide__inner {
    padding-top: 0;
  }
  .slideFam .contentbox .slide__inner h1 {
    font-size: 46px;
  }
  .contentbox {
    margin-top: 0;
  }
  .slideFam .slider__fornt {
    position: relative;
    right: 0;
  }
  .slider--one .slide__inner {
    padding-top: 0;
  }
}

@media #{$sm-layout} {
  .slider--one .slideFam {
    height: auto;
    canvas {
      z-index: 1;
    }
  }
  .slider--2 .slideFam {
    height: 400px;
    .slide__inner {
      padding-top: 0;
      padding-bottom: 15px;
    }
  }

  .slider--3 .slideFam {
    height: 400px;
  }
  .slider--2 .slideFam .slide__inner {
    left: 0;
  }
  .slider--2 .slideFam .slide__inner h1 {
    font-size: 36px;

    padding-right: 0;
  }
  .slider--2 .slideFam .slide__inner p {
    padding-right: 0;
  }
  .slideFam .slider__fornt {
    position: relative;
    right: auto;

    margin-top: 30px;
  }
  .slideFam .slide__inner h1 {
    font-size: 42px;
    line-height: 63px;

    margin-bottom: 37px;
  }
  .slideFam .contentbox .slide__inner h1 {
    font-size: 36px;
    line-height: 51px;
  }
  .contentbox .slide__inner {
    padding-top: 0;
  }
  .slide__inner {
    padding-top: 90px;
  }
  .contentbox {
    margin-top: 0;
  }
  .slider--one .slide__inner {
    padding-top: 0;
  }
  .slider--one .slideFam .container {
    padding-top: 50px;
    padding-bottom: 65px;
  }
}

@media #{$xs-layout} {
  .slideFam .slide__inner h1 {
    font-size: 33px;
    line-height: 47px;

    margin-bottom: 31px;
  }
  a.slide__btn.dg__btn {
    font-size: 13px;
    line-height: 41px;

    height: 41px;
    padding: 0 25px;
  }
  .slideFam .contentbox .slide__inner h1 {
    font-size: 31px;
    line-height: 42px;
  }
  .slide__inner {
    padding-top: 145px;
  }
  .slider--2 .slideFam .slide__inner h1 {
    font-size: 27px;
    line-height: 45px;
  }
  .contentbox {
    margin-top: 0;
  }
}
