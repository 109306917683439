.PhoneInputCountry {
  padding-bottom: 20px;
}

.white__btn {
  button {
    font-size: 14px;
    line-height: 38px;

    display: inline-block;

    height: 40px;
    padding: 0 30px;

    transition: all 0.3s ease 0s;
    text-transform: uppercase;

    color: #fff;
    border: 1px solid #1852b5;
    border-radius: 4px;
    background: #1852b5 none repeat scroll 0 0;
    &:hover {
      color: #1852b5;
      border: 1px solid #1852b5;
      background: transparent none repeat scroll 0 0;
    }
  }
}

.errorText {
  color: red !important;
  font-size: 15px !important;
  margin-left: 15px;
}

.dropdown {
  font-size: 16px;
  font-weight: 500;
  color: #001232;
  font-family: Oswald;
  display: block;
  padding-right: 20px;
  color: #001232;
  border: 0 none;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.list-t-border {
  border: 1px solid #e5e5e5;
}

.bg-hover {
  padding: 20px 20px 20px;
}

.my-pro-list img {
  width: 100%;
  height: 141px;
}

.button-my-pro-list {
  margin-top: 20px;
}

.select-pro-list {
  text-align: right;
}

.controls-list {
  display: inline-block;
  font-size: 24px;
  cursor: pointer;
  height: 34px;
  line-height: 34px;
  margin-top: 40px;
  text-align: center;
  width: 34px;
}

.color-success {
  color: #5cb85c;
}

.color-danger {
  color: #d9534f;
}

@media screen and (max-width: 640px) {
  .f-p-links > li a b {
    font-size: 0 !important;
  }
}
@media screen and (max-width: 992px) {
  .f-p-links > li a {
    // font-size: 10px;
    padding: 12px 5px !important;
  }
}

.market__filter > ul {
  padding: 0;
}

.f-p-links {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.f-p-links > li {
  display: table-cell;
  margin: -2px;
}
.f-p-links > li a {
  background: #1855b7;
  color: #fff;
  display: block;
  padding: 12px 10px;
  text-align: center;
  font-size: 17px;
}
.f-p-links > li a.active {
  background: #729fec;
  color: #fff;
}
.f-p-links > li a:hover {
  background: #729fec;
  color: #191919;
}
.f-p-links > li + li {
  border-left: 1px solid #455d8d;
}
.f-p-links li a svg {
  font-size: 22px;
  vertical-align: sub;
  margin-right: 5px;
}

.noHeight {
  // height: 0px;
  position: relative;
}

.btn {
  font-size: 16px;
  line-height: 38px;
  display: inline-block;
  height: 40px;
  padding: 0 30px;
  transition: all 0.3s ease 0s;
  text-transform: uppercase;
  color: #fff;
  border: 1px solid #1852b5;
  border-radius: 4px;
  background: #1852b5 none repeat scroll 0 0;
  &:hover {
    color: #1852b5;
    background: #ffffff none repeat scroll 0 0;
  }
}

.tag_t {
  color: #333;
  position: absolute;
  top: 14px;
  display: inline-block;
  z-index: 1;
  font-size: 12px;
  padding: 5px 14px;
  background: #ffd119;
  right: 9px;
}

.bottom20 {
  margin-bottom: 20px;
}

.p-image {
  width: 110px !important;
  img {
    width: 100%;
  }
}

.feature-p-text {
  a {
    background: #f7a901;
    color: #181818;
    padding: 8px 16px;
    font-size: 12px;
    border: 1px solid #f7a901;
    border-radius: 4px;
    &:hover {
      color: #181818;
      background: #fff none repeat scroll 0 0;
    }
  }
  p {
    font-size: 12px;
  }
}

.yellowBtn {
  background: #f7a901;
  line-height: 35px;
  color: #181818;
  font-size: 16px;
  border: 1px solid #f7a901;
  text-align: center;
  display: inline-block;
  height: 36px;
  border-radius: 4px;
  font-weight: 700;
  transition: all 0.3s ease 0s;

  &:hover {
    color: #181818;
    background: #ffc03e none repeat scroll 0 0;
  }
}
.vessel_item {
  border: 1px solid #e5e5e5;
  background: #fff;
  position: relative;
}

.favroute {
  border: 1px solid #e5e5e5;
  background: #fff;
  position: relative;

  p {
    display: inline-block;
    line-height: 33px;
    margin-left: 15px;
    font-size: 15px;
    margin-top: 5px;
  }
}

.item {
  margin: 0 15px;
}

.vessel_content {
  .vessel_text {
    padding: 10px 20px;
    a {
      color: #001232;
      transition: 0.5s;
      &:hover {
        color: #f7a901;
      }
    }
  }
}

.vessel_meta {
  border-top: none;
  display: table;
  table-layout: fixed;
  width: 100%;
  .transparent {
    background: inherit;
    border: none;
  }
  span {
    border: none !important;
    display: table-cell;
    font-size: 15px;
    line-height: 38px;
    text-align: center;
  }
}

.listing_full_bg {
  -moz-transition: all ease-in 0.3s;
  -ms-transition: all ease-in 0.3s;
  -o-transition: all ease-in 0.3s;
  -webkit-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s;
}

.listing_inner_full {
  &:hover::after {
    -moz-transition: all ease-in 0.3s;
    -ms-transition: all ease-in 0.3s;
    -o-transition: all ease-in 0.3s;
    -webkit-transition: all ease-in 0.3s;
    transition: all ease-in 0.3s;
  }
}

.listing_inner_full {
  &:after {
    -moz-transition: all ease-in 0.3s;
    -ms-transition: all ease-in 0.3s;
    -o-transition: all ease-in 0.3s;
    -webkit-transition: all ease-in 0.3s;
    transition: all ease-in 0.3s;
  }
}

.listing_inner_full > a {
  display: block;
  padding: 10px 0 0 10px;
}
.listing_inner_full {
  position: relative;
  transition: all 0.7s ease 0s;
  z-index: 1;
}
.listing_inner_full:after {
  background: #1d1d1d;
  content: '';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 0;
  z-index: -1;
}

.listing_full_bg .listing_inner_full:hover::after {
  width: 100%;
}
.listing_full_bg .listing_inner_full:hover h3,
.listing_full_bg .listing_inner_full:hover p {
  color: #fff;
}
.listing_full_bg .listing_inner_full:hover > span a {
  background: #fff;
  color: #000;
}
.listing_inner_full > span {
  position: absolute;
  right: 0;
  top: 8px;
}
.listing_inner_full > span a {
  background: #1d1d1d;
  color: #898989;
  padding: 8px 11px;
}

.listing_full_bg {
  background: #fff;
  margin: 0 30px;
  outline: 3px solid #1d1d1d;
  outline-offset: -10px;
  padding: 10px;
  position: relative;
  top: -80px;
}

.listing_full_bg .listing_inner_full:hover::after {
  width: 100%;
}

.listing_full_bg .listing_inner_full:hover h3,
.listing_full_bg .listing_inner_full:hover p {
  color: #fff;
}

.listing_full_bg .listing_inner_full:hover > span a {
  background: #fff;
  color: #000;
}

.listing_full_bg .property_meta {
  border-right: medium none;
  border-top: 2px solid #1d1d1d;
  display: table;
  table-layout: fixed;
  width: 100%;
}

.listing_full_bg .property_meta > span {
  display: table-cell;
  line-height: 38px;
  text-align: center;
  font-size: 12px;
}

.listing_full_bg h3 {
  font-size: 18px;
}
.listing_full_bg p {
  font-size: 12px;
}

.ais-SearchBox {
  margin: 1em 0;
  position: relative;
}

.ais-Hits-list {
  list-style: none;
}

.ais-SearchBox-submitIcon path {
  d: path(
    'M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'
  );
}

.ais-SearchBox-submitIcon {
  width: 1em;
  height: 1em;
}

.ais-SearchBox-input {
  background: #f9f9f9 none repeat scroll 0 0;
  border: 0 none;
  color: #a0a0a0;
  font-size: 16px;
  height: 56px;
  padding: 0 72px 0 20px;
  width: 100% !important;
  outline: none;
}

.ais-SearchBox-submit {
  background: #f7a901 none repeat scroll 0 0;
  border: 1px solid #f7a901;
  color: #fff;
  font-size: 25px;
  height: 100%;
  padding: 0 14px;
  right: 0;
  top: 0;
  transition: 0.5s;
  outline: none;
  position: absolute;
}

.ais-Pagination {
  margin-top: 1em;
  margin-bottom: 1em;
}

.left-panel {
  float: left;
  width: 250px;
}

.right-panel {
  margin-left: 260px;
}
.ais-RefinementList {
  color: #3a4570;
}

.ais-RefinementList-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.ais-RefinementList-checkbox {
  margin-right: 3px;
}

.ais-RefinementList-count {
  padding: 0.1rem 0.4rem;
  font-size: 0.8rem;
  color: #3a4570;
  background-color: #ffd119;
  border-radius: 8px;
}

.ais-Hits-item {
  margin-bottom: 1em;
  width: calc(50% - 1rem);
}

.ais-Hits-item img {
  margin-right: 1em;
}

.hit-name {
  margin-bottom: 0.5em;
}

.hit-description {
  color: #888;
  font-size: 14px;
  margin-bottom: 0.5em;
}
.ais-Pagination-list {
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: outside none none;
  display: flex !important;
}

.ais-Pagination-item {
  margin-right: 40px;
}

.ais-Pagination-link {
  font-size: 20px;
  font-weight: 700;
  transition: 0.3s;
  color: #9f9f9f;
  text-decoration: none;
}

.ais-Pagination-item--selected {
  line-height: 40px;
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  color: #333333;
  border-radius: 100%;
  background: #fff none repeat scroll 0 0;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 350px;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

/* Select Ad Package styling */
.select_ad__card {
  width: 250px;
  height: 280px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 1px 1px 15px 2px rgba($color: #000000, $alpha: 0.15);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .select_ad__body {
    padding: 15px;
    text-align: center;

    .package_name {
      font-size: 1.5rem;
      font-weight: 600;
      text-transform: capitalize;
    }

    .package_plan {
      span.package_price {
        font-size: 1.2rem;
        font-weight: 600;
      }
    }

    .package_perks {
      ul {
        text-align: left;
        padding-left: 10px;
      }
      ul li {
        list-style-type: none;
        color: #000000;

        .perk_title {
          display: inline-block;
          margin-left: 10px;
        }
      }
    }
  }

  .select_ad__footer {
    .select_ad__button {
      width: 100%;
      padding: 10px;
      border: 1px solid #1852b5;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      background-color: transparent;
      box-sizing: border-box;
      transition: 0.2s ease-in;
      outline: none;

      &:hover,
      &:focus {
        background-color: #1852b5;
        color: #fafafa;
      }
    }

    .select_ad__button.selected {
      border: 1px solid transparent;
      color: #ffffff;
      background-color: #1852b5;

      &:hover {
        background-color: #3373e2;
      }
    }
  }
}
/* End Select Ad Package styling */

.content {
  display: flex;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.content img {
  width: 50%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 768px) {
  .content {
    flex-direction: column;
  }
}

.linkButton {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
  padding-top: 10px;
}

.resendText {
  text-align: center;
  font-size: 14px;
  height: 40px;
  padding: 0 30px;
  color: #069;
  border-radius: 4px;
}

.select-search {
  width: 300px;
  position: relative;
  font-family: 'Nunito Sans', sans-serif;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
* Value wrapper
*/
.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
* Input
*/
.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 40px 0 16px;
  background: #fff;
  border: 1px solid transparent;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
* Options wrapper
*/
.select-search__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

/**
* Options
*/
.select-search__options {
  list-style: none;
}

/**
* Option row
*/
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
* Option
*/
.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #059de8;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: #1cd8e4;
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #1ac3e4;
  color: #fff;
}

/**
 * Group
 */
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
* States
*/
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
* Modifiers
*/
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple)
  .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #1ac3e4;
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}

.main-search-form {
  margin-top: 40px;
  border: 2px solid #0a81e7;
  padding: 24px;
  border-radius: 10px;
}

.blueText {
  color: #001232;
}

.search-input {
  display: block;
  height: 36px;
  width: 100%;
  background: #fff;
  border: 1px solid transparent;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
}

.search-not-found {
  align-items: center;
  background-color: #f5faff;
  border: 1px solid #1d6ebf;
  flex-wrap: wrap;
  margin: 0 0 15px;
  padding: 21px 18px;
  position: relative;
  width: 100%;
}

.search-not-found-icon {
  align-items: center;
  align-self: flex-start;
  border: 2px solid #1d6ebf;
  border-radius: 50%;
  color: #1d6ebf;
  display: flex;
  flex: none;
  font-size: 26px;
  height: 36px;
  justify-content: center;
  margin-right: 15px;
  margin-top: 2px;
  width: 36px;
}

.search-not-found-title {
  color: #010101;
  font-weight: 700;
  margin-bottom: 6px;
}

.search-not-found-subtitle {
  color: #4b4b4b;
  margin-bottom: 3px;
}

.search-not-found-item {
  align-items: center;
  display: flex;
  font-weight: 400;
  margin-bottom: 1px;
}

.new-search {
  border-bottom: 1px dotted rgba(42, 162, 248, 0.2);
}
